@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

html {
  @apply scroll-smooth h-full w-full;
}
.App {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
body {
  @apply scroll-smooth h-full w-full;
  overflow-x: hidden;
}
:focus {
  @apply outline-none;
}

nav .nav-items a.active {
  @apply text-theme relative lg:flex lg:justify-center;
}

footer .nav-items a.active {
  @apply text-theme;
}

.Iconmenu .sideNav {
  @apply md:w-auto w-0;
}
.Iconmenu .sideNav a {
  @apply max-w-62 rounded-none;
}

.Iconmenu .sideNav a:hover {
  @apply absolute w-auto max-w-none rounded-tr-full rounded-br-full;
}

.Iconmenu .sideNav a:hover span {
  @apply block;
}

.Iconmenu .sideNav a span {
  @apply hidden;
}

.Fullmenu .sideNav {
  @apply pr-8 min-w-260;
}

.sideNav a:hover svg,
.sideNav a:focus svg,
.sideNav a.active svg {
  @apply opacity-100;
}

.booked input + label {
  @apply bg-red-500 border-red-500;
}

.notavail input + label {
  @apply bg-gray-400 border-gray-400;
}

.booked input,
.notavail input {
  @apply pointer-events-none;
}

.date-active {
  background-color: #ff6a00 !important;
  @apply bg-theme text-white;
}

.paid {
  @apply text-green-700 bg-green-200;
}

.unpaid {
  @apply text-theme bg-orange-100;
}

#f-wings {
  @apply absolute bottom-0;
}

#co-wings {
  position: relative;
}

.monthdrop .col_mp {
  @apply text-sm font-normal;
}

.monthdrop .col_mp:hover {
  @apply bg-theme text-white;
}

.monthdrop .selected_date_mp {
  @apply pointer-events-none font-bold text-theme;
}

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}
.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 40px;
  height: 20px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}
.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 15px;
  height: 15px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}
.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}
.react-switch-label:active .react-switch-button {
  width: 60px;
}
.bottom22 {
  bottom: 5.5rem;
}
.w-st {
  position: fixed !important;
  top: 0 !important;
}
.w-st1 {
  position: fixed !important;
  left: 0 !important;
}

.topFixed {
  position: sticky;
  padding-left: 95px;
  top: 0;
}

.test {
  @apply hidden !important;
}

tbody td .court {
  width: 100% !important;
}

tbody td .court input:checked + label {
  @apply bg-green-700 border-green-700;
}

thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}

[disabled] {
  opacity: 0.2;
  pointer-events: none;
}

.booktable {
  @apply relative w-full overflow-x-auto;
}

.booktable thead {
  @apply sticky top-0 left-0 z-20;
}

.booktable thead th {
  left: 0;
  width: 95px;
  z-index: 30 !important;
}

.booktable tbody {
  overflow: scroll;
  /* height: 200px; */
}

.booktable .fixedtab {
  width: 97px;
  @apply sticky left-0 z-10;
}

#left-button {
  transform: scaleX(-1);
}

.react-switch-checkbox {
  @apply hidden;
}

.cart-items-list {
  max-height: calc(100% - 62px);
}

.cart-items-list li + li {
  @apply pt-3 mt-3 border-t border-gray-400;
}

.cart-item .item-date {
  height: 70px;
  width: 70px;
}

.titleBar {
  top: 1rem;
}

@media (min-width: 1024px) {
  nav .nav-items a.active:before {
    content: "";
    background: url("assets/title.svg");
    @apply h-4 w-8 absolute -bottom-3 bg-contain bg-no-repeat brightness-0;
  }
}

@media (min-width: 768px) {
  .sideNav a.active {
    @apply bg-theme;
  }
  .Fullview {
    max-width: calc(100% - 260px);
  }

  .Halfview {
    max-width: calc(100% - 74px);
  }

  .Fullviews {
    max-width: calc(100% - 390px);
    /* margin-left:60px; */
  }
  .Halfviews {
    /* max-width: calc(100vw - 64px); */
  }

  .cart-body {
    max-height: calc(100vh - 308px);
  }
}

@media (max-width: 767px) {
  #sidebar {
    @apply absolute;
  }
  .sideNav {
    @apply overflow-hidden;
  }
  .sideNav a.active {
    @apply text-theme;
  }
  .sideNav a.active:hover {
    @apply text-white !important;
  }
  .booktable {
    /* height: calc(100vh - 134px); */
  }
  .cart-body {
    max-height: calc(100vh - 350px);
  }
}
@media (min-width: 768px) {
  .booktable {
    /* height: calc(100vh - 180px); */
  }
}
.courtInfo .tooltipBox + .tooltipBox {
  @apply ml-4;
}

.tool-bar {
  background-color: #000;
  color: #fff;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
  /* position: absolute; */
  z-index: 99;
}
.sidebar {
  background-color: #000;
  /* color: #fff; */
  height: 100vh;
  width: 280px;
  top: 0;
  right: 0 !important;
  transform: translateX(-100%);
  transition: all 0.4s;
}
.sidebar li {
  list-style: none;
  padding: 1rem 2 rem;
  border-bottom: 1px solid;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
}
.sidebar--open {
  transform: translateX(0%);
}
.backdrop--open {
  display: block;
}

/*Accordion*/
.accordion {
  max-width: 768px;
}
.accordion-item {
  list-style: none;
}
.accordion-toggle {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #ccc;
  padding: 1em;
}
.accordion-toggle:hover {
  background-color: #ddd;
}
.accordion-toggle h3 {
  margin: 0;
}
.accordion-content {
  background-color: #eee;
  padding: 1em;
}
.op {
  background-image: url("assets/down-arrow.png");
  display: inline-flex;
  width: 16px;
  height: 16px;
}
.cl {
  background-image: url("assets/right-arrow.png");
  display: inline-flex;
  width: 16px;
  height: 16px;
}

@media (min-width: 1151px) {
  .innerContent {
    @apply pl-4;
  }
}
